import type { AxiosResponse } from 'axios'
import { get } from '../../utils/api'
import type { ICategory } from './types'

export const fetch = (): Promise<AxiosResponse<ICategory[]>> =>
    get<ICategory[]>('/admin/blog/post-categories', {
        include: ['picture'],
        sort: 'created_at'
    })

export const fetchTags = (): Promise<AxiosResponse<void>> =>
    get<void>('/admin/blog/post-tags', { page_size: 1000 })
