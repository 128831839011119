import type {
    ICompany,
    IPreCreateData,
    ICategory,
    IFeature,
    CroppingPhotoPayload
} from './types'
import type { AxiosResponse } from 'axios'
import { del, get, patch, post, upload } from '../../utils/api'

export const sendFinishCreationCompany = async (
    company_id: string
): Promise<AxiosResponse<ICompany>> =>
    await post<ICompany>(`/admin/companies/${company_id}/finish-creation`)

export const saveVideo = async (
    company_id: string,
    videos: string[]
): Promise<AxiosResponse<ICompany>> =>
    await post<ICompany>(`/admin/companies/${company_id}/media/videos/upload`, {
        videos
    })

export const croppingPhoto = async (
    data: CroppingPhotoPayload
): Promise<AxiosResponse<ICompany>> =>
    await patch<ICompany>(
        `/companies/${data.company_id}/media/${data.media_id}`,
        {
            ...data,
            company_id: undefined,
            media_id: undefined
        }
    )

export const uploadGallery = async (
    company_id: string,
    files: File[]
): Promise<AxiosResponse<ICompany>> =>
    await upload<ICompany>(
        `/admin/companies/${company_id}/media/gallery/multiply`,
        files.map(file => ({
            field: 'gallery',
            file
        }))
    )

export const removeMedia = async (
    company_id: string,
    media_id: number
): Promise<AxiosResponse<ICompany>> =>
    await del<ICompany>(
        `/admin/companies/${company_id}/media/gallery/${media_id}`
    )

export const sortGallery = async (
    company_id: string,
    ids: number[]
): Promise<AxiosResponse<ICompany>> =>
    await patch<ICompany>(`/admin/companies/${company_id}/media/gallery`, {
        ids
    })

export const fetchFeatures = async (
    category_id: string
): Promise<AxiosResponse<IFeature[]>> =>
    await get<IFeature[]>('/company-features', { category_id })

export const fetchAllFeatures = async (
    categories: string
): Promise<AxiosResponse<IFeature[]>> =>
    await get<IFeature[]>('/admin/companies/features', {
        'filter[categories]': categories
    })

export const fetchCategories = async (
    type: 'online' | 'offline'
): Promise<AxiosResponse<ICategory[]>> =>
    await get<ICategory[]>('/company-categories', { type })

export const fetchCompany = async (
    company_id: string
): Promise<AxiosResponse<ICompany>> =>
    await get<ICompany>(`/admin/companies/${company_id}`, {
        include:
            'onHouseParameter,onHouseParameter.areas,mainImage,gallery,videos,children,companyTimetables,companyCategories,companyFeatures,companyContacts,companyCommunicationContacts,companySocialContacts,area,companyAreas,activePlanSubscription,activePlanSubscriptionCount,children.onHouseParameter,children.onHouseParameter.areas,dadataArea',
        append: 'summary_status,on_mamado,smart_target_action,cached_displayed'
    })

export const saveCompany = async (
    company_id: string,
    form: Record<string, unknown>
): Promise<AxiosResponse<ICompany>> =>
    await patch<ICompany>(`/admin/companies/${company_id}`, form)

export const createCompany = async (
    form: IPreCreateData
): Promise<AxiosResponse<ICompany>> => {
    const additional = {
        age_min:
            form.age_category === 'all' || form.age_category === 'children'
                ? form.age_min
                : undefined,
        age_max: form.age_category === 'children' ? form.age_max : undefined,
        works_around_the_clock: false
    }

    return await post<ICompany>('/admin/companies', {
        ...form,
        status: 'draft',
        ...additional
    })
}
