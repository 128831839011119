import type { ReactElement } from 'react'
import { Form, Input, Modal } from 'antd'
import type { ITag } from '../../containers/TagsSelector/types.ts'
import { useForm } from 'antd/es/form/Form'
import { useDispatch } from 'react-redux'
import { actions } from '../../containers/SettingsTags/slice.ts'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { settingsTagsSelector } from '../../containers/SettingsTags/selectors.ts'

interface Props {
    open: boolean
    selectedTag: ITag | undefined
    setIsModalEdit: (open: boolean) => void
}

export default function TagWizardModal({
    open,
    selectedTag,
    setIsModalEdit
}: Props): ReactElement {
    const dispatch = useDispatch()

    const [form] = useForm()

    const { isCreateTagProcess } = useAppSelector(settingsTagsSelector)

    const handleSendForm = (form: Record<string, unknown>) => {
        dispatch(actions.createTag({ form }))
    }

    return (
        <Modal
            open={open}
            title={`${selectedTag ? 'Редактирование' : 'Создание'} тега`}
            onCancel={() => setIsModalEdit(false)}
            okText={selectedTag ? 'Редактировать' : 'Создать'}
            onOk={form.submit}
            okButtonProps={{
                loading: isCreateTagProcess
            }}
        >
            <Form layout={'vertical'} form={form} onFinish={handleSendForm}>
                <Form.Item
                    style={{ marginBottom: 10 }}
                    name={'title'}
                    label={'Название тега'}
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите название тега!'
                        }
                    ]}
                >
                    <Input placeholder={'Название тега'} />
                </Form.Item>
                <Form.Item
                    style={{ marginBottom: 10 }}
                    name={'slug'}
                    label={'Слаг тега'}
                    normalize={value =>
                        value
                            .replace(/[^a-zA-Z0-9-]+/g, '-')
                            .replace(/^-+/g, '')
                    }
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите слаг тега!'
                        }
                    ]}
                >
                    <Input placeholder={'Слаг тега'} />
                </Form.Item>
                <Form.Item
                    style={{ marginBottom: 10 }}
                    name={'meta_h1'}
                    label={'H1 заголовок категории'}
                >
                    <Input placeholder={'H1 заголовок категории'} />
                </Form.Item>
                <Form.Item
                    style={{ marginBottom: 10 }}
                    name={'description'}
                    label={'Описание категории'}
                >
                    <Input.TextArea placeholder={'Описание категории'} />
                </Form.Item>
                <Form.Item
                    style={{ marginBottom: 10 }}
                    name={'meta_title'}
                    label={'Meta-title'}
                >
                    <Input placeholder={'Meta-title'} />
                </Form.Item>
                <Form.Item name={'meta_description'} label={'Meta-description'}>
                    <Input.TextArea placeholder={'Meta-description'} />
                </Form.Item>
            </Form>
        </Modal>
    )
}
