import type { AxiosResponse } from 'axios'
import { del, get, patch, post } from '../../utils/api'
import type { ICategory } from './types'

export const fetch = (): Promise<AxiosResponse<ICategory[]>> =>
    get<ICategory[]>('/admin/blog/post-categories', {
        include: ['picture', 'companyCategory'],
        sort: 'created_at'
    })

export const fetchTags = (): Promise<AxiosResponse<void>> =>
    get<void>('/admin/blog/post-tags')

export const save = (
    form: Record<string, unknown>
): Promise<AxiosResponse<void>> => post('/admin/blog/post-tags', { ...form })

export const sendWizard = (
    form: Record<string, unknown>,
    postCategoryId?: string
): Promise<AxiosResponse<ICategory[]>> =>
    postCategoryId
        ? patch(
              `/admin/blog/post-categories${postCategoryId ? `/${postCategoryId}` : ''}`,
              { ...form }
          )
        : post('/admin/blog/post-categories', { ...form })

export const removeCategory = (
    postCategoryId: string
): Promise<AxiosResponse<void>> =>
    del(`/admin/blog/post-categories/${postCategoryId}`)

export const createTag = (
    form: Record<string, unknown>
): Promise<AxiosResponse<void>> => post<void>('/admin/blog/post-tags', form)

export const editTag = (
    tagId: string,
    form: Record<string, unknown>
): Promise<AxiosResponse<void>> =>
    patch<void>(`/admin/blog/post-tags/${tagId}`, form)
