import type { ReactElement } from 'react'
import {
    Col,
    Row,
    Steps,
    Typography,
    Card,
    Divider,
    Space,
    Tag,
    Select,
    Form,
    Button,
    Modal,
    Popconfirm
} from 'antd'
import TypeStep from './components/TypeStep'
import PlaceCompanyStep from './components/PlaceCompanyStep'
import ViewStep from './components/ViewStep'
import BasicStep from './components/BasicStep'
import { useAppSelector } from '../../hooks/useAppSelector'
import { useDispatch } from 'react-redux'
import { actions } from './slice'
import CategoriesStep from './components/CategoriesStep'
import DatesStep from './components/DatesStep'
import GalleryStep from './components/GalleryStep'
import VideoGalleryStep from './components/VideoGalleryStep'
import DescriptionStep from './components/DescriptionStep'
import TargetStep from './components/TargetStep'
import FAQStep from './components/FAQStep'
import PriceStep from './components/PriceStep'
import DetailsStep from './components/DetailsStep'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import RegionStep from './components/RegionStep'
import TitleStep from '../../components/TitleStep'
import {
    CheckOutlined,
    CloseOutlined,
    CompassOutlined,
    ExclamationCircleFilled,
    ExclamationCircleOutlined,
    HomeOutlined,
    LinkOutlined
} from '@ant-design/icons'
import NotFoundStep from '../../components/NotFoundStep'
import Status from '../../components/Status'
import { eventWizardSelector } from './selectors'
import EventPublishedBanner from '../../components/EventPublishedBanner'

// Styles
import styles from './styles.module.scss'
import { EventWizardContext } from '../../context/eventWizard-context'
import dayjs from 'dayjs'

const { Title, Paragraph } = Typography

const steps = [
    {
        title: 'Тип предложения',
        steptitle: 'Выберите тип предложения',
        component: <TypeStep />
    },
    {
        title: 'Место проведения',
        steptitle: 'Выберите место проведения',
        keyerror: 'invalid_places',
        component: <PlaceCompanyStep />,
        slug: 'place'
    },
    {
        title: 'Регион показа',
        steptitle: 'Регион показа',
        keyerror: 'invalid_areas',
        component: <RegionStep />,
        slug: 'region'
    },
    {
        title: 'Вид предложения',
        steptitle: 'Выберите вид предложения',
        keyerror: 'invalid_badge',
        component: <ViewStep />,
        slug: 'view'
    },
    {
        title: 'Основное',
        steptitle: 'Основное',
        keyerror: 'invalid_main_info',
        component: <BasicStep />,
        slug: 'basic'
    },
    {
        title: 'Категории',
        steptitle: 'Категории',
        keyerror: 'invalid_categories',
        component: <CategoriesStep />,
        slug: 'categories'
    },
    {
        title: 'Период проведения',
        steptitle: 'Период проведения',
        keyerror: 'invalid_schedules,invalid_timetables',
        component: <DatesStep />,
        slug: 'dates'
    },
    {
        title: 'Фотогалерея',
        steptitle: 'Фотогалерея',
        keyerror: 'invalid_gallery',
        component: <GalleryStep />,
        slug: 'gallery'
    },
    {
        title: 'Видеогалерея',
        component: <VideoGalleryStep />,
        slug: 'video'
    },
    {
        title: 'Описание',
        steptitle: 'Описание',
        keyerror: 'invalid_about',
        component: <DescriptionStep />,
        slug: 'description'
    },
    {
        title: 'Целевое действие',
        steptitle: 'Целевое действие',
        keyerror: 'invalid_target_action',
        component: <TargetStep />,
        slug: 'target'
    },
    {
        title: 'Частые вопросы и условия',
        component: <FAQStep />,
        slug: 'faq'
    },
    {
        title: 'Вид билета и цена',
        steptitle: 'Вид билета и цена',
        keyerror: 'invalid_price_type',
        component: <PriceStep />,
        slug: 'price'
    },
    {
        title: 'Детали',
        steptitle: 'Детали',
        keyerror: 'invalid_details',
        component: <DetailsStep />,
        slug: 'details'
    }
]

interface Props {
    isCreate?: boolean
}

export default function EventWizard({ isCreate }: Props): ReactElement {
    const [isModalConfirmPublished, setIsModalConfirmPublished] =
        useState(false)
    const [isModalHasChanges, setIsModalHasChanges] = useState<number | false>(
        false
    )
    const [isDraftModal, setIsDraftModal] = useState(false)

    const { currentEvent, step, hasStepChanges, preCreateData, isSaving } =
        useAppSelector(eventWizardSelector)

    const prevStatus = useRef(currentEvent?.status)

    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const isFree = currentEvent ? currentEvent.badge === 'free' : false

    const generalType = currentEvent ? currentEvent.type : preCreateData.type

    const ignoreSteps = useMemo(() => {
        const collect: number[] = []

        if (isFree) {
            collect.push(12)
        }

        if (generalType === 'offline') {
            collect.push(2)
        }

        return collect
    }, [isFree, generalType])

    const filteredSteps = steps.filter(
        (_, index) => !ignoreSteps.includes(index)
    )

    const currentStep = filteredSteps.find((_, index) => index === step)

    const names = currentEvent
        ? [currentEvent.companies[0]?.name, currentEvent.name].filter(Boolean)
        : false

    const StepComponent = currentStep ? currentStep.component : null

    useEffect(() => {
        const page = searchParams.get('page')

        const findStepIndex = filteredSteps.findIndex(
            step => step.slug === page
        )

        if (page && findStepIndex) {
            dispatch(actions.setStep(findStepIndex))
        }
    }, [searchParams])

    useEffect(() => {
        if (isCreate) {
            dispatch(actions.setCreateMode(true))
        }
    }, [])

    const handleNextStep = (value: number) => {
        const findStep = filteredSteps.find((_, index) => index === value)

        if (findStep && !isCreate) {
            if (findStep.slug) {
                setSearchParams({
                    page: findStep.slug
                })
            } else {
                setSearchParams({})
            }
        }

        dispatch(actions.setStep(value))
    }

    const handleChangePublishedStatus = () => {
        if (currentEvent) {
            dispatch(
                actions.saveEvent({
                    event_id: currentEvent.event_id,
                    form: {
                        published: !currentEvent.published
                    }
                })
            )
            setIsModalConfirmPublished(false)
        }
    }

    if (!StepComponent) {
        return (
            <NotFoundStep>
                {'Шаг не доступен для вашего предложения'}
            </NotFoundStep>
        )
    }

    return (
        <>
            <Row justify={'space-between'} align={'top'}>
                <Col span={8}>
                    <Title level={3} style={{ margin: 0 }}>
                        {isCreate
                            ? 'Создание предложения'
                            : 'Редактирование предложения'}
                    </Title>
                    {!isCreate && currentEvent && names ? (
                        currentEvent?.companies?.length > 1 ? (
                            <Popconfirm
                                icon={<CompassOutlined />}
                                title={
                                    currentEvent.companies[0].name ||
                                    'Выберите компанию по адресу'
                                }
                                description={
                                    <>
                                        {currentEvent.companies.map(item => (
                                            <a
                                                key={item.company_id}
                                                href={`/companies/${item.company_id}`}
                                                target={'_blank'}
                                                rel={'noreferrer'}
                                                className={styles.company__url}
                                            >
                                                <Paragraph
                                                    style={{
                                                        fontSize: 12,
                                                        marginBottom: 0
                                                    }}
                                                >
                                                    {item.text_address ||
                                                        item.additional_text_address ||
                                                        item.name}
                                                </Paragraph>
                                            </a>
                                        ))}
                                    </>
                                }
                                showCancel={false}
                                okButtonProps={{
                                    style: { display: 'none' }
                                }}
                            >
                                <Paragraph
                                    className={styles.company__url}
                                    style={{
                                        fontSize: 18,
                                        marginBottom: 0
                                    }}
                                >
                                    {names.join(' / ')}
                                    <LinkOutlined style={{ marginLeft: 10 }} />
                                </Paragraph>
                            </Popconfirm>
                        ) : (
                            <>
                                <Title style={{ margin: 0 }} level={4}>
                                    {names[1]}
                                </Title>
                                <a
                                    href={`/companies/${currentEvent?.companies[0].company_id}`}
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                    className={styles.company__url}
                                >
                                    <Row>
                                        <HomeOutlined
                                            style={{ color: 'black' }}
                                        />
                                        <Paragraph
                                            style={{
                                                fontSize: 14,
                                                marginBottom: 0,
                                                marginLeft: 5
                                            }}
                                        >
                                            {names[0]}
                                        </Paragraph>
                                    </Row>
                                </a>
                            </>
                        )
                    ) : null}
                    {currentEvent ? (
                        <>
                            {currentEvent.slug_title ? (
                                <Paragraph
                                    style={{ margin: 0, cursor: 'pointer' }}
                                    copyable
                                    onClick={() =>
                                        window.open(
                                            `${process.env.SITE_URL}/${currentEvent?.dadata_area ? (currentEvent.dadata_area.short_slug ?? currentEvent.dadata_area.slug) : 'rossiya'}/e/${currentEvent.slug_title}`,
                                            '_blank'
                                        )
                                    }
                                >
                                    {`${process.env.SITE_URL}/${currentEvent?.dadata_area ? (currentEvent.dadata_area.short_slug ?? currentEvent.dadata_area.slug) : 'rossiya'}/e/${currentEvent.slug_title}`}
                                </Paragraph>
                            ) : null}
                            <Status
                                type={currentEvent.type}
                                published={currentEvent.published}
                                isEvent={true}
                            />
                        </>
                    ) : null}
                </Col>
                {currentEvent ? (
                    <Col
                        span={10}
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-end'
                        }}
                    >
                        {currentEvent?.summary_status ? (
                            <Space
                                size={[0, 'small']}
                                wrap
                                direction={'vertical'}
                            >
                                {currentEvent.summary_status.map(
                                    (status, index) => (
                                        <Tag
                                            key={`summary-${index}`}
                                            bordered={false}
                                            color={'error'}
                                        >
                                            {status}
                                        </Tag>
                                    )
                                )}
                                {currentEvent.status === 'draft' ? (
                                    <Button
                                        style={{
                                            float: 'right',
                                            marginRight: 8
                                        }}
                                        type={'primary'}
                                        onClick={() =>
                                            currentEvent.status === 'draft'
                                                ? setIsDraftModal(true)
                                                : navigate('/events/')
                                        }
                                        icon={<CheckOutlined />}
                                    >
                                        {'Сохранить и закрыть'}
                                    </Button>
                                ) : null}
                            </Space>
                        ) : (
                            <Row gutter={8}>
                                <Col>
                                    <Form.Item
                                        label={'Статус публикации предложения'}
                                    >
                                        <Select
                                            disabled={isSaving}
                                            loading={isSaving}
                                            value={
                                                currentEvent?.published
                                                    ? 'published'
                                                    : 'unpublished'
                                            }
                                            defaultValue={
                                                currentEvent?.published
                                                    ? 'published'
                                                    : 'unpublished'
                                            }
                                            style={{ width: 170 }}
                                            onChange={() =>
                                                setIsModalConfirmPublished(true)
                                            }
                                            options={[
                                                {
                                                    value: 'published',
                                                    label: 'Опубликовано'
                                                },
                                                {
                                                    value: 'unpublished',
                                                    label: 'Не опубликовано'
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Link to={'/events'}>
                                        <Button icon={<CloseOutlined />}>
                                            {'Закрыть'}
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        )}
                        <div style={{ flex: '100%', textAlign: 'end' }}>
                            <Paragraph
                                style={{ margin: '5px 0 0', cursor: 'default' }}
                            >{`Дата создания: ${dayjs(currentEvent.created_at).format('DD.MM.YYYY HH:mm')}`}</Paragraph>
                            <Paragraph
                                style={{ margin: 0, cursor: 'default' }}
                            >{`Дата изменения: ${dayjs(currentEvent.updated_at).format('DD.MM.YYYY HH:mm')}`}</Paragraph>
                        </div>
                    </Col>
                ) : null}
            </Row>
            <Divider style={{ marginBottom: 30 }} />
            <Row gutter={[50, 50]}>
                <Col span={5}>
                    <Title level={5} style={{ margin: '0 0 20px' }}>
                        {'Разделы'}
                    </Title>
                    <Steps
                        progressDot
                        size={'small'}
                        direction={'vertical'}
                        current={step}
                        onChange={value => {
                            if (hasStepChanges) {
                                setIsModalHasChanges(value)
                            } else {
                                handleNextStep(value)
                            }
                        }}
                        items={
                            isCreate
                                ? filteredSteps
                                      .filter(
                                          (_, index) =>
                                              index <
                                              (generalType === 'offline'
                                                  ? 4
                                                  : 5)
                                      )
                                      .map((item, index) => {
                                          const condition =
                                              generalType === 'offline'
                                                  ? preCreateData.companies
                                                        ?.length === 0 &&
                                                    index > 1
                                                  : index >
                                                        (preCreateData.companies
                                                            ?.length === 0
                                                            ? 1
                                                            : 2) &&
                                                    preCreateData.areas
                                                        ?.length === 0

                                          if (condition) {
                                              return {
                                                  ...item,
                                                  disabled: true
                                              }
                                          }

                                          return item
                                      })
                                : filteredSteps.map(item => {
                                      const isEmpty =
                                          item.keyerror &&
                                          currentEvent &&
                                          Array.isArray(
                                              currentEvent.summary_status_all_codes
                                          )
                                              ? currentEvent.summary_status_all_codes.some(
                                                    code =>
                                                        item.keyerror
                                                            .split(',')
                                                            .includes(code)
                                                )
                                              : false

                                      return {
                                          ...item,
                                          ...(isEmpty
                                              ? {
                                                    title: (
                                                        <Row align={'middle'}>
                                                            {item.title}
                                                            <ExclamationCircleFilled
                                                                style={{
                                                                    marginLeft: 4,
                                                                    color: '#ff4d4f'
                                                                }}
                                                            />
                                                        </Row>
                                                    )
                                                }
                                              : {})
                                      }
                                  })
                        }
                    />
                </Col>
                <Col span={19}>
                    <Card style={{ background: 'rgba(255, 255, 255, 0.7)' }}>
                        {currentStep?.steptitle ? (
                            <TitleStep>{currentStep.steptitle}</TitleStep>
                        ) : null}
                        <EventWizardContext.Provider
                            value={{
                                changeMenu: value => {
                                    if (hasStepChanges) {
                                        setIsModalHasChanges(value)
                                    } else {
                                        handleNextStep(value)
                                    }
                                }
                            }}
                        >
                            {StepComponent}
                        </EventWizardContext.Provider>
                    </Card>
                </Col>
            </Row>
            {prevStatus.current === 'draft' &&
            currentEvent?.status === 'active' ? (
                <EventPublishedBanner />
            ) : null}
            <Modal
                open={isModalConfirmPublished}
                title={'Изменение статуса публикации'}
                cancelText={'Нет'}
                okText={'Да'}
                onOk={handleChangePublishedStatus}
                onCancel={() => setIsModalConfirmPublished(false)}
            >
                {currentEvent?.published
                    ? 'Вы уверены, что хотите снять предложение с публикации?'
                    : 'Вы уверены, что хотите опубликовать предложение?'}
            </Modal>
            <Modal
                open={typeof isModalHasChanges === 'number'}
                title={
                    <Row align={'middle'}>
                        <ExclamationCircleOutlined
                            style={{
                                fontSize: 22,
                                color: 'red',
                                marginRight: 10
                            }}
                        />{' '}
                        <Paragraph
                            style={{
                                margin: 0,
                                fontWeight: '600',
                                fontSize: 16
                            }}
                        >
                            {'Внимание'}
                        </Paragraph>
                    </Row>
                }
                okText={'Все равно перейти'}
                cancelText={'Остаться'}
                onOk={() => {
                    if (typeof isModalHasChanges === 'number') {
                        handleNextStep(isModalHasChanges)
                    }
                    dispatch(actions.setHasStepChanges(false))
                    setIsModalHasChanges(false)
                }}
                onCancel={() => setIsModalHasChanges(false)}
            >
                <p>
                    {
                        'У вас остались не сохраненные изменения на текущем шаге, все равно перейти на другой шаг?'
                    }
                </p>
            </Modal>
            <Modal
                open={isDraftModal}
                onOk={() => setIsDraftModal(false)}
                onCancel={() => setIsDraftModal(false)}
                afterClose={() => navigate('/events')}
                okText={'Понятно'}
                cancelButtonProps={{
                    style: { display: 'none' }
                }}
                title={'Черновик сохранен'}
            >
                <Paragraph>
                    {
                        'Можете вернуться к редактированию разделов в любой момент.'
                    }
                </Paragraph>
            </Modal>
        </>
    )
}
